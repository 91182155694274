.custom-calendar {
  border: none !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__month-container {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__header {
  background-color: #f0f0f0 !important;
  border: none !important;
  width: 100% !important;
  text-align: center;
  margin: 0 !important;
  padding: 0.5rem 0 !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex !important;
  justify-content: space-around !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  width: 2.5rem !important;
  height: 2.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
